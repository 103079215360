import styled from "styled-components";
import { getSize, responsive } from "~/helpers/style";
import { UIProps } from "~/types/style";

interface IGridItem extends UIProps {
  cols?: number;
  noInnerMargin?: boolean;
}

export const Grid = styled.div<{ noMargin: boolean } & UIProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noMargin, ...rest }) =>
    noMargin ? "0" : getSize(2)(rest)};

  ${responsive("laptop")} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${getSize(1)};
  }
`;

export const GridItem = styled.div<IGridItem>`
  grid-column: span ${({ cols = 12 }) => cols};
  margin-bottom: ${({ noInnerMargin }) => getSize(noInnerMargin ? 0 : 1)};
`;
